import { Parallax } from "react-scroll-parallax";

function App() {
  return (
    <>
      <div
        className="flex flex-col justify-center items-center bg-cover bg-center bg-no-repeat px-3 md:px-20 relative"
        // style={{ backgroundImage: `url(${"./images/backHero.jpg"})` }}
      >
        <Parallax
          speed={10}
          translateY={[40, 0]} //50
          className="absolute top-0 left-0 w-full h-full z-0"
        >
          <img
            className="h-full w-full object-cover scale-150"
            src="./images/backHero.jpg"
          />
        </Parallax>
        <img className="w-full mt-20 z-10" src="./images/headerLogo.svg" />
        <p className="text-white w-full md:w-1/2 text-center mt-24 font-HelveticaNeue z-10">
          ¡Bienvenido/a a la Misión Natura 2000! <br />
          Adéntrate en esta aventura y descubre junto <br /> a Hali aspectos
          fascinantes de este planeta <br /> azul en el que vivimos.¿Estás
          preparado/a?
        </p>
        <button
          onClick={() =>
            window.open("https://virtual.descubrenatura2000marina.es/", "_self")
          }
          className="bg-[#F5AB00] text-submon-blue font-HelveticaNeueBold p-5 rounded-full mt-10 mb-24 z-10"
        >
          Accede al espacio virtual
        </button>
      </div>

      <div className="flex flex-col justify-center items-center  bg-[#00A19E] py-20 px-3 md:px-20 relative">
        <Parallax
          speed={50}
          translateX={[0, -20]}
          translateY={[0, 0]}
          className="absolute top-0 right-0 p-4 invisible md:visible"
        >
          <img className="w-20" src="./images/gaviota.svg" />
        </Parallax>
        <Parallax
          speed={1000}
          translateX={[-10, -50]}
          translateY={[0, 0]}
          className="absolute bottom-0 right-0 pr-4"
        >
          <img className="w-16 md:w-20" src="./images/planta.svg" />
        </Parallax>
        <Parallax
          speed={1000}
          translateX={[10, 50]}
          translateY={[0, 0]}
          className="absolute bottom-0 left-0 m-8 "
        >
          <img className="w-24" src="./images/tortuga.svg" />
        </Parallax>
        <h1 className="max-w-full md:max-w-[50%] font-BenHand text-submon-blue text-3xl text-center">
          LIFE INTEMARES: motor para la ampliación y gestión eficaz de los
          espacios marinos protegidos.
        </h1>
        <p className="max-w-full md:max-w-[50%] text-center mt-5 text-submon-blue font-HelveticaNeueMedium">
          Esta iniciativa se enmarca en el programa de sensibilización del
          proyecto LIFE INTEMARES, que coordina la Fundación Biodiversidad del
          Ministerio para la Transición Ecológica y el Reto Demográfico
        </p>
        <a
          className="underline text-submon-blue mt-5"
          href="https://intemares.es/"
        >
          Saber más <span className="no-underline">→</span>
        </a>
      </div>

      <div
        style={{ backgroundImage: `url(${"./images/backActividades.jpg"})` }}
        className="flex flex-col justify-center items-center  bg-cover bg-center bg-no-repeat py-10 px-3 md:px-20 relative"
      >
        <h1 className="text-submon-blue font-HelveticaNeueBold text-xl">
          Recursos y actividades
        </h1>
        <button
          onClick={() =>
            window.open(
              "https://www.youtube.com/watch?v=i4WYHsbmSVY&list=PLubSqcUI6ZqX-8ZSCQsB8nkopzmt_7PRH&ab_channel=Fundaci%C3%B3nBiodiversidad",
              "_blank"
            )
          }
          className="bg-submon-blue text-white font-HelveticaNeueMedium text-xl p-5 rounded-full mt-20 md:min-w-[25rem] min-h-[6rem] z-40"
        >
          Sumérgete en los espacios <br /> Natura 2000 marinos
        </button>
        <button
          onClick={() =>
            window.open(
              "https://intemares.es/prensa/actualidad/mojate-por-la-conservacion-red-natura-2000-life-intemares",
              "_blank"
            )
          }
          className="bg-submon-blue text-white font-HelveticaNeueMedium text-xl p-5 rounded-full mt-32 md:min-w-[25rem]  min-h-[6rem] z-40"
        >
          ¡Mójate por la conservación!
          <br />
          Participa en la campaña
        </button>
        <button
          onClick={() =>
            window.open(
              "https://www.youtube.com/watch?v=CVRc4tuhbZ8&list=PLubSqcUI6ZqXlkPswirGvG4mNr9hfS8tl&ab_channel=Fundaci%C3%B3nBiodiversidad",
              "_blank"
            )
          }
          className="bg-submon-blue text-white font-HelveticaNeueMedium text-xl p-5 rounded-full mt-32 md:min-w-[25rem] min-h-[6rem] z-40"
        >
          Conoce la Red Natura 2000 marina y sus beneficios
        </button>
        <button
          onClick={() =>
            window.open(
              "https://intemares.es/prensa/actualidad/el-buzo-invisible",
              "_blank"
            )
          }
          className="bg-submon-blue text-white font-HelveticaNeueMedium text-xl p-5 rounded-full mt-32 md:min-w-[25rem] min-h-[6rem] z-40"
        >
          Accede a la obra de teatro
        </button>
        <Parallax
          speed={1000}
          translateX={[-10, 10]}
          translateY={[0, 0]}
          className="absolute top-0 left-0 w-full flex justify-center z-0 my-20 invisible md:visible"
        >
          <img className="w-[25%]" src="./images/backParallax.svg" />
        </Parallax>
      </div>
      <div className="flex flex-col justify-center items-center  bg-submon-blue py-10 px-3 md:px-20">
        <img src="./images/footer.svg" className="w-full object-contain" />
      </div>
    </>
  );
}

export default App;
